<template>
  <v-container class="pa-2" style="background: #e5e7eb">
    <v-row
      class="mx-auto pa-2 maincontainer"
      style="overflow: hidden !important"
    >
      <v-col cols="12" md="9">
        <div
          class="pa-4"
          style="
            margin: 0 -0.5rem;
            border-radius: 8px;
            background: rgb(249 250 251);
            border: 0px solid #d7d7d7;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px -1px rgba(0, 0, 0, 0.1);
          "
        >
          <v-img
            class="onboarding-image"
            height="400px"
            style="
              background-size: cover;
              margin: -1.01rem;
              position: relative;
              border-radius: 8px 8px 0px 0px;
            "
            max-width="100vw"
            dark
            gradient="to top, rgb(249 250 251), #ffffff90, #ffffff00"
            src="https://www.klinikum-stuttgart.de/fileadmin/_processed_/8/5/csm_19_Akutraum_Ina_Team_AErzte_Pflege_Arzt_Notfall_Untersuchung_b053d0a23b.jpg"
            ><p
              class="text-center lstight"
              style="
                position: relative;
                top: 18rem !important;
                color: #111 !important;
                font-size: 2.4rem;
                font-weight: 600;
              "
            >
              Wilkommen in der Notaufnahme
            </p>
            >
            <p
              class="text-center lstight"
              style="
                margin-top: -1rem;
                line-height: 1.2;
                position: relative;
                top: 18rem !important;
                font-size: 1.2rem;
                color: #444 !important;
                font-weight: 400;
              "
            >
              Gut, dass du da bist!<br />Auf dieser Seite findest du ein wenig
              Starthilfe für die Zeit im Olgahospital.
            </p></v-img
          >
          <v-row class="mt-16 mx-2 pa-4 mb-4">
            <div
              style="
                display: grid;
                grid-gap: 3rem;
                grid-template-columns: repeat(3, minmax(0, 1fr));
              "
            >
              <div v-for="(item, index) in items" :key="index">
                <span
                  ><v-icon
                    class="mr-2"
                    small
                    style="top: -2px"
                    v-text="item.icon"
                  ></v-icon>
                  <p
                    class="text-xs lstight"
                    style="display: inline; font-weight: 500; font-size: 1rem"
                    v-text="item.title"
                  ></p></span
                ><v-divider
                  style="margin-top: 0.5rem; margin-bottom: 1rem"
                ></v-divider>
                <p
                  style="font-size: 0.875rem; color: #6b7880 !important"
                  v-text="item.text"
                ></p>
              </div>
            </div>
          </v-row></div
      ></v-col>
      <v-col cols="12" md="3" class="pa-4" style="z-index: 1000 !important">
        <div
          class="py-2 honto-shadow"
          style="
            margin: -0.25rem !important;
            border-radius: 8px;
            margin-bottom: -1.5rem;
            background: rgb(249, 250, 251);
            border: 1px solid rgba(51,65,85,.2)!important;
            height: fit-content !important;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
              0 1px 2px -1px rgba(0, 0, 0, 0.1);
          "
        >
          <v-card flat style="background: #ffffff00 !important">
            <v-list
              flat
              class="lstight"
              style="background: #ffffff00 !important"
            >
              <!--  <v-list-item-group>
                    <v-list-item v-for="(item, index) in items" :key="index">
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title style="font-size:.875rem!important; font-weight:500;"
                          v-text="item.text"
                        ></v-list-item-title
                        ><v-divider
                          v-if="index < items.length - 1"
                          :key="index"
                        ></v-divider>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>-->

              <v-list
                flat
                style="background: #ffffff00 !important"
                subheader
                two-line
              >
                <v-subheader
                  class="lstight"
                  style="
                    font-weight: 500 !important;
                    font-size: 1rem;
                    color: black;
                    margin: 0.25rem 0;
                    margin-left: 0.35rem;
                  "
                  >Wichtige Dateien</v-subheader
                >
                <v-divider
                  style="
                    height: 5px !important;
                    position: relative;
                    left: 0rem;
                    margin: -0.6rem 1.5rem;
                    margin-bottom: 0.55rem;
                    padding: 0;
                    color: red;
                    border-width: 1px !important;
                    border-color: var(--border-color-light) !important;
                  "
                ></v-divider>
                <v-list-item
                  style="min-height: 24px"
                  v-for="file in files"
                  :key="file.title"
                >
                  <v-list-item-avatar
                    style="
                      width: 24px;
                      height: 24px !important;
                      margin: 0;
                      margin-left: -0.5rem;
                      padding: 0;
                    "
                  >
                    <v-icon light small v-text="file.icon"></v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 0.825rem !important"
                      v-text="file.title"
                    ></v-list-item-title>

                    <v-list-item-subtitle
                      class="lstight"
                      style="font-size: 0.725rem !important"
                      v-text="file.subtitle"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      text
                      class="lstight"
                      style="
                        max-height: 24px !important;
                        letter-spacing: normal;
                        font-weight: 400;
                        font-size: 0.755rem;
                        text-transform: none;
                      "
                      color="primary"
                      >Download</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-list>
          </v-card>
        </div>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
// import HeaderTabs from "../components/HeaderTabs.vue";

export default {
  components: {
    //  HeaderTabs,
  },

  name: "Onboarding",
  data() {
    return {
      items: [
        {
          title: "Honto Anleitung",
          text: "Wo finde ich was? Eigentlich findest du alles in Honto. Nimm dir Zeit und mach dich mit der App vertraut.",
          icon: "mdi-help",
        },
        {
          title: "Ansprechpartner",
          text: "Die Ansprechpartner für neue Kollegen sind Michael Müller und Anna Berger.",
          icon: "mdi-account",
        },
        {
          title: "Telefonliste",
          text: "Hier findest du eine Liste mit allen wichtigen Nummern, die dich bei der Arbeit unterstützt.",
          icon: "mdi-phone-outline",
        },
        {
          title: "Medikamentenliste",
          text: "Sehr nützlich für die Arbeit im gesamten Olgahospital ist eine Medikamentenliste, die zum Nachschlagen für jegliche Art von medikamenten dient.",
          icon: "mdi-pill",
        },
        {
          title: "Ärztliche Übergabe",
          text: "Frisch dabei? Dann lies dir unsere SOP zur ärztlichen Übergabe durch. Das spart Zeit und du bist dank fixer Übergabe schneller zuhause.",
          icon: "mdi-chat-outline",
        },
        {
          title: "Checkliste PINA",
          text: "Bevor du dir 2kg SOPs und Standards ausdruckst, schütze lieber den Wald und lade dir diese Datei mit den relevantesten Standards zum Start in der PINA herunter.",
          icon: "mdi-clock-outline",
        },
        {
          title: "Dienstplanung",
          text: "Auch du kommst in den Genuss von Dienstplanungsrunden und musst deine Freiwünsche tapfer No-Go-Tage rechtzeitig an unsere lieben Dientplaner weitergeben",
          icon: "mdi-set-center-right",
        },
        {
          title: "Speisekarte Casino",
          text: "Kleiner Spaß - das Casino wirst du sowieso nie von innen sehen. Man erzählt sich aber, dass es dort sehr schön sei und es sogar Essen gebe.",
          icon: "mdi-food-outline",
        },
      ],
      tips: [
        { text: "Ansprechpartner", icon: "mdi-help" },
        { text: "Checkliste PINA-Start", icon: "mdi-checkbox-marked-outline" },
        { text: "Telefonlisten", icon: "mdi-phone-outline" },
        { text: "Medikamentenliste", icon: "mdi-pill" },
        { text: "Ärztliche Übergabe", icon: "mdi-chat-outline" },
        { text: "Dienstplanung", icon: "mdi-clock-outline" },
        { text: "Anleitung Honto", icon: "mdi-set-center-right" },
        { text: "Speisekarte Casino ;-)", icon: "mdi-food-outline" },
      ],
      files: [
        {
          color: "blue",
          icon: "mdi-paperclip",
          title: "Anleitung Honto",
        },
        {
          color: "amber",
          icon: "mdi-paperclip",
          title: "Telefonliste",
        },
        {
          color: "amber",
          icon: "mdi-paperclip",
          title: "Medikamentenliste",
        },
        {
          color: "amber",
          icon: "mdi-paperclip",
          title: "Ärztliche Übergabe",
        },
        {
          color: "amber",
          icon: "mdi-paperclip",
          title: "Checkliste PINA",
        },
        {
          color: "amber",
          icon: "mdi-paperclip",
          title: "Dienstpläne",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.v-list-item__icon {
  align-self: flex-start;
  margin: 12px 0;
}
@media screen and (max-width: 1264px) {
  .onboarding-image {
    max-height: 200px;
    max-width: 100vw;
    background-size: cover;
    /* margin: -2rem; */
    margin: -0.77rem !important;
  }
}
.column {
  flex-basis: 100%;
}

@media screen and (min-width: 800px) {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .column {
    flex: 1;
  }
  ._25 {
    flex: 2.5;
  }
  ._5 {
    flex: 5;
  }
}
</style>
